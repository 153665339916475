import { SVG } from '../types';

export function NoSound({ strokeWidth = 2, ...props }: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9167 9.75L16.4167 16.25"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M16.4167 9.75L22.9167 16.25"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M12 7L7.55556 10.4286H4V15.5714H7.55556L12 19V7Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
