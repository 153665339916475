import { SVG } from '../types';

export function Pause(props: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 6H11V20H7V6Z" fill="currentColor" />
      <path d="M14.5091 6H18.5091V20H14.5091V6Z" fill="currentColor" />
    </svg>
  );
}
