import { styled } from '@/stitches.config';

type IconOverlayProps = {
  tabIndex?: number;
  onClick?: () => void;
  children: React.ReactNode;
  isFixed?: boolean;
};

export function IconOverlay({
  tabIndex,
  onClick,
  children,
  isFixed,
}: IconOverlayProps) {
  return (
    <>
      {onClick ? (
        <Overlay
          as="button"
          aria-hidden="true"
          onClick={onClick}
          tabIndex={tabIndex}
          isFixed={isFixed}
        >
          {children}
        </Overlay>
      ) : (
        <Overlay aria-hidden="true">{children}</Overlay>
      )}
    </>
  );
}

/**
 *  Clip-path Values Explained
 *  On non-retina screens there's always 1px "missing" around the edges, thus making any background colour visible
 *  To hide it, we expand the size of the clip-path by 1px in each direction to cover the background entirely
 */
export const ICON_BLOCK_DEFAULT_CLIP_PATH = {
  clipPath:
    'polygon(-1px calc(100% + 1px), calc(100% + 1px) calc(100% + 1px), calc(100% + 1px) calc(100% + 1px), -1px calc(100% + 1px))',
};

export const ICON_BLOCK_ON_HOVER_CLIP_PATH = {
  clipPath:
    'polygon(-1px calc(100% + 1px), calc(100% + 1px) calc(100% + 1px), calc(100% + 1px) -1px, -1px -1px)',
};

const Overlay = styled('div', {
  alignItems: 'center',
  backgroundColor: '$background',
  // boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.2)',
  color: '$foreground',
  display: 'flex',
  padding: '$2',
  justifyContent: 'center',
  position: 'absolute',
  right: '$4',
  top: '$4',
  zIndex: '$iconOverlay',

  //targeting HoverBlock defined in publicity.tsx
  '&:hover, &:active': {
    '& > div': ICON_BLOCK_ON_HOVER_CLIP_PATH,
  },

  '&:focus-visible': {
    outlineStyle: 'dashed',
    outlineWidth: '1px',
    outlineColor: 'currentColor',
    outlineOffset: '2px',

    '& > div': {
      ...ICON_BLOCK_ON_HOVER_CLIP_PATH,
    },
  },

  variants: {
    isFixed: {
      true: {
        position: 'fixed',

        '@bp3': {
          marginRight: '$4',
        },
      },
    },
  },
});
