import { SVG } from '../types';

export function Sound({ strokeWidth = 2, ...props }: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6592 5.34084C22.6901 7.37239 23.831 10.1274 23.831 13C23.831 15.8726 22.6901 18.6276 20.6592 20.6592"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinejoin="bevel"
      />
      <path
        d="M12 7L7.55556 10.4286H4V15.5714H7.55556L12 19V7Z"
        fill="currentColor"
      />
      <path
        d="M17.835 9.16501C18.8505 10.1808 19.4209 11.5583 19.4209 12.9946C19.4209 14.4309 18.8505 15.8084 17.835 16.8242"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
