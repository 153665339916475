import { useHasMounted } from '@/hooks/use-has-mounted';
import { event } from '@/lib/gtag';
import { CSS, styled } from '@/stitches.config';
import { useEffect, useRef, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { isMobileOnly, isSafari } from 'react-device-detect';
import ReactPlayer from 'react-player/vimeo';
import { useIntersection } from 'react-use';
import screenfull from 'screenfull';
import { Controls } from './controls';

// Disabling custom controls while full screen api is not supported by IOS safari: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
export const IS_SAFARI_IPHONE = isMobileOnly && isSafari;

interface VimeoPlayerProps {
  videoId: string;
  onStartedChange?: (started: boolean) => void;
  autoplay?: 'none' | 'immediate' | 'in-view';
  hideControls?: boolean;
  loop?: boolean;
  light?: boolean;
  isFixed?: boolean;
  aspectRatio?: number | 'native';
  css?: CSS;
}

type Progress = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

export function VimeoPlayer({
  videoId,
  autoplay = 'none',
  hideControls = false,
  loop = false,
  light = false,
  isFixed = false,
  onStartedChange,
  aspectRatio = 'native',
  css,
}: VimeoPlayerProps) {
  const [isMuted, setMuted] = useState(autoplay !== 'immediate');
  const [isPlaying, setPlaying] = useState(autoplay === 'immediate');
  const [isSeeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videoAspectRatio, setVideoAspectRatio] = useState(
    typeof aspectRatio === 'number' ? aspectRatio : null
  );

  const playerRef = useRef<ReactPlayer>(null);
  const hasMounted = useHasMounted();
  const isMounted = useRef(true);

  const wrapperRef = useRef(null);
  const intersection = useIntersection(
    autoplay === 'in-view' ? wrapperRef : { current: null },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    }
  );

  useEffect(() => {
    if (intersection?.isIntersecting) {
      setPlaying(intersection.isIntersecting);
    } else {
      setPlaying(false);
    }
  }, [intersection]);

  useEffect(() => {
    if (autoplay !== 'in-view') setMuted(!isPlaying);
  }, [autoplay, isPlaying]);

  // This is used to check in async callbacks if the component is mounted before
  // doing things like 'setState()', as that's undesired behaviour in React
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOnReady = async () => {
    if (aspectRatio === 'native') {
      // This looks weird I know. First player is the general react-player. Second
      // player is the more specific Vimeo player within the react-player. That on
      // itself has another player which is the ACTUAL Vimeo player SDK from Vimeo
      // itself. We can use that one to get more detailed info about the video
      // (width, height). Sadly the react-player types do not show this though.
      // @ts-expect-error this nested player actually exists
      const vimeoSdk = playerRef.current?.player?.player?.player;

      Promise.all([vimeoSdk.getVideoWidth(), vimeoSdk.getVideoHeight()]).then(
        ([width, height]) =>
          // Check if the component is currently mounted before setting state
          isMounted.current && setVideoAspectRatio(width / height)
      );
    }
  };

  const handleFullScreen = () => {
    if (wrapperRef.current) {
      screenfull.toggle(wrapperRef.current);
    }
  };

  const handlePlayPause = () => {
    event({
      action: 'click',
      category: 'Vimeo',
      label: isPlaying ? 'Pause' : 'Play',
    });
    setPlaying(!isPlaying);
  };

  const handleToggleMuted = () => {
    setMuted(!isMuted);
  };

  const handlePlay = () => {
    event({
      action: 'click',
      category: 'Vimeo',
      label: 'Play',
    });

    setPlaying(true);
    onStartedChange && onStartedChange(true);
  };

  const handlePause = () => {
    event({
      action: 'click',
      category: 'Vimeo',
      label: 'Pause',
    });
    setPlaying(false);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (playerRef.current) {
      setSeeking(false);
      playerRef.current.seekTo(
        parseFloat((e.target as HTMLInputElement).value)
      );
    }
  };

  const handleProgress = (progress: Progress) => {
    if (!isSeeking) {
      setPlayed(progress.played);
    }
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleEnd = () => {
    // Videos on a loop should not handle a video ending
    if (loop) {
      return;
    }

    if (playerRef.current) {
      // Videos with autoPlay and no controls should also loop
      if (autoplay === 'in-view' && hideControls) {
        setPlayed(0);
        setPlaying(true);
      } else {
        // All other videos can just go to pause
        setPlaying(false);
        setPlayed(0);

        onStartedChange && onStartedChange(false);
      }
    }
  };

  const hasCookieConsent = getCookieConsentValue() === 'true';

  return (
    <FullscreenWrapper css={css} ref={wrapperRef}>
      <Container
        css={{
          '--expanded-controls-width': '0px',
          '&:hover': {
            '--expanded-controls-width': isPlaying ? '18rem' : '0px',
          },

          // Don't show player before aspect ratio has been determined to
          // prevent janky layout shift
          paddingTop: videoAspectRatio
            ? `${100 / videoAspectRatio}%`
            : undefined,
          visibility: videoAspectRatio === null ? 'hidden' : undefined,
        }}
        isFixed={isFixed}
      >
        {hasMounted && (
          <ReactPlayer
            className="player"
            ref={playerRef}
            width="100%"
            height="100%"
            url={`https://vimeo.com/${videoId}`}
            playing={isPlaying}
            loop={loop}
            light={light}
            volume={1}
            muted={isMuted}
            playsinline={true}
            onPlay={() => handlePlay()}
            onPause={() => handlePause()}
            onProgress={(progress: Progress) => handleProgress(progress)}
            onDuration={(duration: number) => handleDuration(duration)}
            onEnded={() => handleEnd()}
            onReady={handleOnReady}
            config={{
              playerOptions: {
                dnt: !hasCookieConsent,
                quality: 'auto',
                responsive: true,
                controls: IS_SAFARI_IPHONE && !hideControls ? true : false,
                autoplay: autoplay === 'immediate',
              },
            }}
          />
        )}
        {!hideControls && !IS_SAFARI_IPHONE && (
          <>
            <Controls
              played={played}
              isPlaying={isPlaying}
              isMuted={isMuted}
              duration={duration}
              handlePlayPause={handlePlayPause}
              handleToggleMuted={handleToggleMuted}
              handleSeekMouseDown={handleSeekMouseDown}
              handleSeekChange={handleSeekChange}
              handleSeekMouseUp={handleSeekMouseUp}
              handleFullScreen={handleFullScreen}
            />
          </>
        )}
      </Container>
    </FullscreenWrapper>
  );
}

const FullscreenWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: 'black',
});

const Container = styled('div', {
  width: '100%',

  '.player': {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  variants: {
    isFixed: {
      true: {
        position: 'fixed',
      },
      false: {
        position: 'relative',
      },
    },
  },
  defaultVariants: {
    isFixed: false,
  },
});
