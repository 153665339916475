import { SVG } from '../types';

export function Play(props: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 6L19 13L9 20V6Z" fill="currentColor" />
    </svg>
  );
}
