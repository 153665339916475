import { SVG } from '../types';

export function EnterFullScreen({ strokeWidth = 2, ...props }: SVG) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 6.02226L20 6L19.9777 11"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M19.9777 15L20 20L15 19.9777"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M11 19.9777L6 20L6.02226 15"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.02226 11L6 6L11 6.02226"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
