import { AccessibleIcon } from '@/components/icons';
import { NoSound } from '@/components/icons/video/no-sound';
import { Pause } from '@/components/icons/video/pause';
import { Play } from '@/components/icons/video/play';
import { Sound } from '@/components/icons/video/sound';
import { EnterFullScreen } from '../icons/video/enter-fullscreen';
import {
  ICON_BLOCK_DEFAULT_CLIP_PATH,
  ICON_BLOCK_ON_HOVER_CLIP_PATH,
} from '../icon-overlay';
import { buttonFocusStyle } from '../button-link';
import { styled } from '@/stitches.config';

type IconProps = {
  alt: string;
  icon: 'play' | 'pause' | 'no-sound' | 'sound' | 'enter-fullscreen';
};

type DurationProps = {
  duration: number;
  seconds: number;
};

type HoverContentProps = {
  children: React.ReactNode;
};

type ControlsProps = {
  isPlaying: boolean;
  isMuted: boolean;
  played: number;
  handleSeekMouseDown: () => void;
  handleSeekChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSeekMouseUp: (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
  handleToggleMuted: (e?: unknown) => void;
  handlePlayPause: (e?: unknown) => void;
  handleFullScreen: (e?: unknown) => void;
  duration: number;
};

export function Controls({
  isPlaying,
  isMuted,
  played,
  handleSeekMouseDown,
  handleSeekChange,
  handleSeekMouseUp,
  handleToggleMuted,
  handlePlayPause,
  handleFullScreen,
  duration,
}: ControlsProps) {
  return (
    <Container id="controls-container">
      <ControlBar>
        <Button onClick={() => handlePlayPause()}>
          {isPlaying ? (
            <Icon alt="Pause video" icon="pause" />
          ) : (
            <Icon alt="Play video" icon="play" />
          )}

          <HoverContent>
            {isPlaying ? (
              <Icon alt="Pause video" icon="pause" />
            ) : (
              <Icon alt="Play video" icon="play" />
            )}
          </HoverContent>
        </Button>

        <ExpandedControls>
          <Range
            css={{ '--progress': played, flexGrow: 1 }}
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={() => handleSeekMouseDown()}
            onChange={(e) => handleSeekChange(e)}
            onMouseUp={(e) => handleSeekMouseUp(e)}
          />

          <Duration duration={duration} seconds={duration * played} />

          <Button onClick={() => handleToggleMuted()}>
            {isMuted ? (
              <Icon alt="Mute video" icon="no-sound" />
            ) : (
              <Icon alt="Unmute video" icon="sound" />
            )}

            <HoverContent>
              {isMuted ? (
                <Icon alt="Mute video" icon="no-sound" />
              ) : (
                <Icon alt="Unmute video" icon="sound" />
              )}
            </HoverContent>
          </Button>

          <Button onClick={() => handleFullScreen()}>
            <EnterFullScreen />
            <HoverContent>
              <EnterFullScreen />
            </HoverContent>
          </Button>
        </ExpandedControls>
      </ControlBar>
    </Container>
  );
}

const ExpandedControls = styled('div', {
  alignItems: 'center',
  display: 'inline-flex',
  width: 'var(--expanded-controls-width)',
  transition: 'all 0.5s ease-in-out',
  overflow: 'hidden',
  flexShrink: 1,
});

const Container = styled('div', {
  position: 'absolute',
  bottom: '$5',
  left: '$5',
  width: 'calc(100% - 2 * $5)',
  zIndex: 9,
});

const ControlBar = styled('div', {
  alignItems: 'center',
  backgroundColor: '$background',
  color: '$foreground',
  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.2)',
  display: 'inline-flex',
  maxWidth: '100%',
});

const IconHoverBlock = styled('span', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  backgroundColor: '$foreground',
  padding: 'calc($1 + 2px)',

  ...ICON_BLOCK_DEFAULT_CLIP_PATH,

  transition: 'clip-path 320ms ease-out',
});

const DuplicateContentBlock = styled('span', {
  position: 'relative',
  display: 'block',
  color: '$background',
});

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
  padding: 'calc($1 + 2px)', // keeping the original dimensions
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  position: 'relative',

  '&:hover, &:active': {
    [`${IconHoverBlock}`]: {
      ...ICON_BLOCK_ON_HOVER_CLIP_PATH,
    },
  },

  '&:focus-visible': {
    ...buttonFocusStyle,
    outlineOffset: '-2px',
  },

  '> div': {
    display: 'block !important',
  },
});

const rangeThumbStyles = {
  position: 'relative',
  zIndex: 1,
  appearance: 'none',
  '-webkit-appearance': 'none',
  background: '$foreground',
  border: '0 !important',
  borderRadius: '50%',
  cursor: 'pointer',
  height: '$2',
  width: '$2',
};

const Range = styled('input', {
  cursor: 'pointer',
  appearance: 'none',
  '-webkit-appearance': 'none',
  backgroundColor: '$gray4',
  border: 0,
  height: '2px',
  position: 'relative',
  width: '100%',
  minWidth: '20px', // Make sure the peg will always fit to avoid it jumping around while expanding
  marginLeft: '$3',

  '&:hover': {
    '&::before': {
      backgroundColor: '$gray11',
    },
    '&::-webkit-slider-thumb': {
      backgroundColor: '$gray11',
    },
    '&::-moz-slider-thumb': {
      backgroundColor: '$gray12',
    },
    '&::-ms-thumb': {
      backgroundColor: '$gray11',
    },
  },

  '&::before': {
    backgroundColor: '$foreground',
    content: '',
    height: '100%',
    position: 'absolute',
    width: `calc((var(--progress) / 0.999999) * 100%)`,
  },
  '&::-webkit-slider-thumb': rangeThumbStyles,
  '&::-moz-slider-thumb': rangeThumbStyles,
  '&::-ms-thumb': rangeThumbStyles,
});

function Icon({ alt, icon }: IconProps) {
  return (
    <AccessibleIcon label={alt}>
      <>
        {icon === 'play' && <Play />}
        {icon === 'pause' && <Pause />}
        {icon === 'no-sound' && <NoSound />}
        {icon === 'sound' && <Sound />}
        {icon === 'enter-fullscreen' && <EnterFullScreen />}
      </>
    </AccessibleIcon>
  );
}

const Time = styled('time', {
  fontSize: '$1',
  margin: '0 $2',
});

function Duration({ duration, seconds }: DurationProps) {
  let time = new Date(seconds * 1000).toISOString();
  const anHour = 3600; // 3600 seconds in an hour

  if (duration > anHour) {
    // show time as 00:00:00
    time = time.substr(11, 8);
  } else {
    // show time as 00:00
    time = time.substr(14, 5);
  }

  return (
    <Time
      dateTime={`${Math.floor(seconds / 60)}m ${Math.round(seconds % 60)}s`}
    >
      {time}
    </Time>
  );
}

function HoverContent({ children }: HoverContentProps) {
  return (
    <IconHoverBlock aria-hidden="true">
      <DuplicateContentBlock>{children}</DuplicateContentBlock>
    </IconHoverBlock>
  );
}
